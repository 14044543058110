var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#tab-1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_vm._v(" Asignatura ")])]}}])},[_c('span',[_vm._v("Detalles asignatura.")])])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_vm._v(" Añadir ")])]}}])},[_c('span',[_vm._v("Muestra todos los alumnos inscritos en la plataforma, mas no en la asignatura.")])])],1),_c('v-tab',{attrs:{"href":"#tab-3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_vm._v(" Listado ")])]}}])},[_c('span',[_vm._v("Muestra todos los alumnos inscritos en la asignatura.")])])],1),_c('v-tab',{attrs:{"href":"#tab-4"}},[_vm._v("Crear Actividad")]),_c('v-tab',{attrs:{"href":"#tab-5"}},[_vm._v("Encuesta")]),_c('v-tab',{attrs:{"href":"#tab-6"}},[_vm._v("Actividades Creadas")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('lesson-info')],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('add-students')],1),_c('v-tab-item',{attrs:{"value":"tab-3"}},[_c('current-students')],1),_c('v-tab-item',{attrs:{"value":"tab-4"}},[_c('assignment-form',{attrs:{"lessonId":this.$route.params.id}})],1),_c('v-tab-item',{attrs:{"value":"tab-5"}},[_c('poll-activity-form',{attrs:{"lessonId":this.$route.params.id}})],1),_c('v-tab-item',{attrs:{"value":"tab-6"}},[_c('lesson-activities')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }