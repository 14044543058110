<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col justify="center">
                <v-card>
                    <v-form @submit.prevent="submit" ref="form" lazy-validation>
                        <v-card-text>
                            <v-select v-model="form.poll" :items="polls" item-text="title"
                                prepend-icon="mdi-format-title" label="Seleccione una encuesta" required return-object>
                            </v-select>
                            <date-component @date="form.limit = $event" />


                            <v-textarea prepend-icon="mdi-format-text-variant" v-model="form.description" auto-grow
                                filled color="deep-purple" label="Instrucciones" rows="3"></v-textarea>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn v-if="isFormValid" color="primary" type="submit">
                                Crear Encuesta
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DateComponent from "../../UI/DateComponent";
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: {
        lessonId: {
            type: String,
        },
    },
    components: { DateComponent },
    computed: {
        polls() {
            return this.$store.getters["teacher/getPolls"];
        },
        isFormValid() {
            if (
                !this.form.poll ||
                this.form.description === "" ||
                !this.form.limit
            ) {
                return false;
            }
            return true;
        },
    },
    data() {
        return {
            selected: [],
            form: {
                lessonId: parseInt(this.lessonId),
                poll: null,
                limit: null,
                description: "",
            },
        };
    },
    methods: {
        async getPolls() {
            await this.$store.dispatch("teacher/poll");
        },
        async submit() {
            await Swal.fire({
                title: "¿Desea crear la encuesta?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí!",
                cancelButtonText: "Volver",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await Swal.fire("Creada", "Encuesta creada con éxito", "success");

                    let data = {
                        pollId: this.form.poll.id,
                        lessonId: this.form.lessonId,
                        limit: this.form.limit,
                        description: this.form.description,
                    };

                    try {
                        await axios.post("teacher/poll_activity", data);
                        this.resetForm();
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        },
        resetForm() {
            this.selected = [];
            this.form = {
                poll:null,
                text: null,
                limit: null,
                description: "",
            };
        },
    },
    created() {
        this.getPolls();
    },
};
</script>