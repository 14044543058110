var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loading-component'):_c('div',[_vm._l((_vm.questions),function(question,index){return _c('v-card',{key:index,staticClass:"mt-4",attrs:{"width":"100vw"}},[_c('v-card-title',[_vm._v(" Pregunta: "+_vm._s(index + 1))]),_c('v-card-title',[_vm._v(" Puntaje: "+_vm._s(_vm.findReply(_vm.questionAnswers[index]).answer.isCorrect ? ((question.question.points) + "/" + (question.question.points)) : ("0/" + (question.question.points)))+" ")]),_c('v-card-text',[_c('div',{staticClass:"text--primary"},[_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(question.question.description)+" ")]),_c('p',{staticClass:"text-justify"},[_vm._v(_vm._s(question.question.title))])])]),(question.question.type === 0)?_c('v-container',{attrs:{"fluid":""}},[_c('v-textarea',{attrs:{"disabled":"","background-color":"light-blue lighten-4","color":"teal"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Respuesta: "+_vm._s(_vm.questionAnswers[index].answer))])]},proxy:true}],null,true)})],1):_vm._e(),(question.question.type === 1)?_c('v-container',{attrs:{"fluid":""}},[_c('v-radio-group',_vm._l((question.question.answer),function(alternative,i){return _c('v-radio',{key:i,attrs:{"disabled":"","color":"red","off-icon":_vm.getIcon(alternative, _vm.findReply(_vm.questionAnswers[index]).answer)},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
              alternative.id === _vm.findReply(_vm.questionAnswers[index]).answer.id &&
              _vm.findReply(_vm.questionAnswers[index]).answer.isCorrect
            )?_c('h4',{staticClass:"text-left",staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(alternative.isCorrect ? ((alternative.description) + " (Respuesta correcta)") : alternative.description)+" ")]):_vm._e(),(
              _vm.findReply(_vm.questionAnswers[index]).answer.id === alternative.id &&
              !_vm.findReply(_vm.questionAnswers[index]).answer.isCorrect
            )?_c('h4',{staticClass:"text-left",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(alternative.isCorrect ? ((alternative.description) + " (Respuesta correcta)") : alternative.description)+" ")]):_vm._e(),(_vm.findReply(_vm.questionAnswers[index]).answer.id  !== alternative.id)?_c('h4',{staticClass:"text-left",style:({ color: alternative.isCorrect ? 'green' : '' })},[_vm._v(" "+_vm._s(alternative.isCorrect ? ((alternative.description) + " (Respuesta correcta)") : alternative.description)+" ")]):_vm._e()]},proxy:true}],null,true)})}),1)],1):_vm._e(),(question.question.type === 2)?_c('v-container',{attrs:{"fluid":""}},_vm._l((question.question.answer),function(alternative,i){return _c('v-checkbox',{key:i,attrs:{"disabled":"","label":alternative.description,"value":alternative,"off-icon":_vm.getIconMultiple(alternative, _vm.questionAnswers[index])},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            _vm.questionAnswers[index].answer.some(
              function (val) { return val.id === alternative.id; }
            ) && alternative.isCorrect
          )?_c('h4',{staticClass:"text-left",staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(alternative.description)+" (Respuesta Correcta) ")]):(
            _vm.questionAnswers[index].answer.some(
              function (val) { return val.id === alternative.id; }
            ) && !alternative.isCorrect
          )?_c('h4',{staticClass:"text-left",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(alternative.description)+" ")]):(
            _vm.questionAnswers[index].answer.find(
              function (val) { return val.id !== alternative.id; }
            )
          )?_c('h4',{staticClass:"text-left",style:({ color: alternative.isCorrect ? 'green' : '' })},[_vm._v(" "+_vm._s(alternative.isCorrect ? ((alternative.description) + " (Respuesta correcta)") : alternative.description)+" ")]):_vm._e()]},proxy:true}],null,true)})}),1):_vm._e()],1)}),(_vm.questions.length === 0)?_c('v-card',[_vm._v(" Texto no posee actividad. ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }