<template>
  <student-drawer>
    <v-col xl="12" lg="12" md="12" sm="12" xs="12" align="center">
      <template slot:default>
        <router-view />
      </template>
    </v-col>
  </student-drawer>
</template>

<script>
import StudentDrawer from "../../components/students/StudentDrawer";
export default {
  components: { StudentDrawer }
}
</script>