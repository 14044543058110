<template>
  <v-card color="grey lighten-4" flat height="100%" width="100%" tile>
    <v-toolbar dense color="primary" height="70px">
      <v-app-bar-nav-icon class="white--text" @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title class="white--text">{{currentRouteName}}</v-toolbar-title>

      <v-spacer></v-spacer>

    </v-toolbar>
    <v-navigation-drawer v-model="drawer" absolute temporary color="primary">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title white--text">
              Rol
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">Estudiante</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title white--text">
              {{ userInfo ? `${userInfo.name} ${userInfo.lastname} ${userInfo.secondLastName}` : '' }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">{{ userInfo ? userInfo.email : '' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item link @click="changePage('asignaturas')">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-school</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="white--text">Asignaturas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-row>
        <v-col class="mt-10" cols="12">
          <v-btn @click="logOut" block color="error">Cerrar Sesión</v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-card height="100%">
      <slot>

      </slot>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      group: null,
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo;
    },
    currentRouteName() {
      const name = this.$route.path.split('/')[2];
      return name;
    }
  },
  methods: {
    async logOut() {
      await this.$store.dispatch('logOut');
      await this.$router.push('/login');
    },
    changePage(where) {
      if (where === 'mensajes' && this.$route.path !== '/estudiante/' + where) {
        this.$router.push('/estudiante/' + where)
      }
      if (where === 'textos' && this.$route.path !== '/estudiante/' + where) {
        this.$router.push('/estudiante/' + where)
      }
      if (where === 'asignaturas' && this.$route.path !== '/estudiante/' + where) {
        this.$router.push('/estudiante/' + where)
      }
    }
  }
}
</script>