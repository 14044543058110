<template>
  <div>

    <v-tabs v-model="tab">
      <v-tab href="#tab-1">Pendientes</v-tab>
      <!-- <v-tab href="#tab-2">Completadas</v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <unfinish-poll :loading="loading" />
      </v-tab-item>

      <!-- <v-tab-item value="tab-2">
      </v-tab-item> -->


    </v-tabs-items>


  </div>
</template>
<script>
import UnfinishPoll from "./poll/unfinish/UnfinishPoll";
export default {
  data() {
    return {
      tab: null,
      loading: null,
    }
  },
  components: { UnfinishPoll },

  async created() {
    this.loading = true;
    let id = this.$route.params.id;
    await this.$store.dispatch('student/pollsStudent', id);
    this.loading = false;
  }
}
</script>