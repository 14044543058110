<template>
  <v-container fluid>
    <!-- <v-row v-if="activities.length > 0">
      <v-col v-for="(activity, i) in activities" :key="i">
        <card-component :description="activity.description">
          <template>
            <student-list :activityId="activity.id" activity-type="activity" />
          </template>
          <template slot="details">
            <v-chip class="ma-2" color="indigo" label text-color="white">
              <v-icon left>
                mdi-label
              </v-icon>
              Actividad
            </v-chip>
          </template>
        </card-component>
      </v-col>
    </v-row>
    <v-row v-if="polls.length > 0">
      <v-col v-for="(poll, i) in polls" :key="i">
        <card-component :description="poll.description">
          <template>
            <student-list :activityId="poll.id" activity-type="poll" />
          </template>
          <template slot="details">
            <v-chip class="ma-2" color="pink" label text-color="white">
              <v-icon left>
                mdi-label
              </v-icon>
              Encuesta
            </v-chip>
          </template>
        </card-component>
      </v-col>
    </v-row> -->
    <v-row v-if="loading" justify="center">
      <v-col>
        <v-progress-circular v-if="loading" size="200" :width="3" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-card v-if="!loading && activities.length > 0">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar actividad" single-line hide-details>
        </v-text-field>
      </v-card-title>
      <v-data-table hide-default-footer disable-pagination :search="search" :items="activities" item-key="id"
        :headers="headers">
        <template v-slot:item.actions="{ item }">
          <student-list :activityId="item.id" :activity-type="item.type === 'activity' ? 'activity' : 'poll'" />
        </template>
        <template v-slot:item.type="{ item }">
          <v-chip class="ma-2" :color="item.type === 'activity' ? 'indigo' : 'primary'" label text-color="white">
            <v-icon left>
              mdi-label
            </v-icon>
            {{ item.type === 'activity' ? 'Actividad' : 'Encuesta' }}
          </v-chip>
        </template>

        <template v-slot:item.excel="{ item }">
          <v-btn :loading="loadingDownload" @click="exportExcel(item)" :disabled="item.reply.length === 0" class="mx-2"
            fab dark small color="success">
            <v-icon dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.checkbox="{ item }">
          <v-checkbox v-model="item.checkBox" :disabled="item.reply.length === 0" />
        </template>
      </v-data-table>

    </v-card>
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="-1">
        Descargar Archivos
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="exportMultipleExcel" :loading="loadingDownload">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import StudentList from "../../../../components/teacher/StudentList";

export default {
  components: { StudentList, },
  data() {
    return {
      loadingDownload: false,
      snackbar: false,
      search: '',
      headers: [],
      loading: false,
      lessonId: null,
      activities: [],
      polls: []
    }
  },
  computed: {
    hasSomeCheckBox() {
      const hasSome = this.activities.some((val) => val.checkBox);
      return hasSome;
    },
  },
  watch: {
    hasSomeCheckBox(val) {
      if (val) this.snackbar = true;
      else this.snackbar = false;
    }
  },
  methods: {
    async exportMultipleExcel() {
      try {
        this.loadingDownload = true;
        const data = this.activities.filter(val => val.checkBox).map(val => val.id);
        const options = { responseType: "arraybuffer", 'Access-Control-Allow-Origin': '*' };
        const response = await axios.post('teacher/reply/exportMultipleResults', data, options);
        console.log(response.data);
        const url = window.URL.createObjectURL(
          new Blob([response.data])
        );
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", 'actividad.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const checkBox = this.activities.map(val => {
          return {
            ...val,
            checkBox: false
          }
        })
        this.activities = checkBox;

      } catch (e) {
        console.log(e);
      } finally {
        this.loadingDownload = false;

      }
    },
    async exportExcel({ id, type }) {
      try {
        this.loadingDownload = true;

        const options = { responseType: "arraybuffer", 'Access-Control-Allow-Origin': '*' };
        const route = type === 'poll' ? 'teacher/poll_reply/exportResults/' : 'teacher/reply/exportResults/';
        const response = await axios.get(route + id, options);

        const url = window.URL.createObjectURL(
          new Blob([response.data])
        );
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", 'actividad.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingDownload = false;

      }
    },
    async getActivities() {

      await axios.get('teacher/activityTeacher/' + this.lessonId)
        .then(response => {
          const { activity } = response.data;
          const { poll } = response.data;
          const activityType = activity.map(el => { return { ...el, type: 'activity' } });
          const pollType = poll.map(el => { return { ...el, type: 'poll' } });
          const concat = activityType.concat(pollType);
          const checkBox = concat.map(val => {
            return {
              ...val,
              checkBox: false
            }
          })
          this.activities = checkBox;
        })
    },
    async activityDetails(id) {
      await this.$router.push('/profe/grafico/' + id)
    },
  },
  async created() {
    this.loading = true;
    this.lessonId = this.$route.params.id;
    await this.getActivities();
    this.loading = false;
    this.headers.unshift(
      {
        text: 'Descripción',
        align: 'start',
        value: 'description',
        sortable: false,
      },
      {
        text: 'Tipo',
        align: 'start',
        value: 'type',
        sortable: false,
      },
      {
        text: 'Detalle estudiante',
        align: 'start',
        value: 'actions',
        sortable: false,
      },
      {
        text: 'Exportar excel',
        align: 'start',
        value: 'excel',
        sortable: false,
      },
      {
        text: 'Exportar múltiple',
        align: 'start',
        value: 'checkbox',
        sortable: false,
      },
    )
  }
}
</script>
