var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loading-component'):_c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v("Texto")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v("Palabras Guardadas")]),_c('v-tab',{attrs:{"href":"#tab-3"}},[_vm._v("Actividades")]),_c('v-tab',{attrs:{"disabled":""}},[_vm._v("Tiempo "+_vm._s(_vm.intervalTime))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('v-card',{staticClass:"mt-4",attrs:{"width":"100vw"}},[(!_vm.hideTitle)?_c('v-card-title',{staticStyle:{"border-width":"medium"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Autor","value":_vm.textData.author}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Título","value":_vm.textData.title}})],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.textData.font && _vm.textData.font.length > 0)?_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Fuente","value":_vm.textData.font}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[(
                _vm.textData.publicationYear &&
                _vm.textData.publicationYear.length > 0
              )?_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Año de publicación","value":_vm.textData.publicationYear}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.textData.type && _vm.textData.type.length > 0)?_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Tipo de texto","value":_vm.textData.type}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[(_vm.textData.description && _vm.textData.description.length > 0)?_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Descripción","value":_vm.textData.description}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){_vm.hideTitle = true}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-off ")])]}}],null,false,2397807654)},[_c('span',[_vm._v("Esconder Info")])])],1)],1)],1):_vm._e(),(_vm.hideTitle)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){_vm.hideTitle = false}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,false,3573705423)},[_c('span',[_vm._v("Mostrar Info")])]):_vm._e(),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-card-text',[(_vm.textData.type !== 'Minijuego')?_c('v-container',{ref:"target",attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.textParagraphs),function(data,i){return _c('v-col',{key:i,staticClass:"text--primary",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-justify",style:({ fontSize: _vm.textSize }),domProps:{"innerHTML":_vm._s(data.description)}}),_c('v-text-field',{attrs:{"disabled":"","label":_vm.questionsFromText[i] && _vm.questionsFromText[i].answer !== ''
                    ? _vm.questionsFromText[i].answer
                    : 'No se realizó pregunta'}})],1)}),1)],1):_c('v-container',{ref:"target",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('strong',[_vm._v("Orden correcto")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('strong',[_vm._v("Orden estudiante")])])],1),_vm._l((_vm.textParagraphs),function(data,i){return _c('v-row',{key:i},[_c('v-col',{staticClass:"text--primary",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-justify",style:({ fontSize: _vm.textSize }),domProps:{"innerHTML":_vm._s(data.description)}})]),_c('v-col',{staticClass:"text--primary",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-justify",style:({ fontSize: _vm.textSize, color: data.id === _vm.order[i] ? 'green' : 'red' })},[_vm._v(" "+_vm._s(_vm.textParagraphs.find(function (el) { return el.id === _vm.order[i]; }).description))])])],1)})],2)],1)],1)],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('words-table',{attrs:{"words":_vm.savedWords}})],1),_c('v-tab-item',{attrs:{"value":"tab-3"}},[_c('student-reply')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }