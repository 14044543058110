<template>
    <v-row justify="center">
        <v-dialog persistent v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2 white--text" color="deep-purple accent-4" v-bind="attrs" v-on="on">
                    Palabras clave
                </v-btn>
            </template>
            <v-card>
                <v-form @submit.prevent="submit" ref="form" lazy-validation>
                    <v-card-text>
                        <v-row v-for="(word, i) in  form.words" :key="i">
                            <v-col cols="8">
                                <v-text-field v-model="word.word"
                                    label="Palabra clave (debe aparecer en el párrafo seleccionado, fijarse también en mayúsculas y tildes)">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="word.link" label="Enlace"></v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-btn @click="() => form.words.splice(i, 1)" fab x-small class="mt-3">
                                    <v-icon color="red">
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </v-col>

                        </v-row>
                        <v-btn @click="() => form.words.push({word: '', link: ''})">
                            <v-icon>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn color="red" text @click="cancel">
                            Salir
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text type="submit">
                            Guardar palabras
                        </v-btn>
                    </v-card-actions>
                </v-form>

            </v-card>

            <div>
                <v-snackbar :color="color" v-model="snackbar">
                    {{ snackText }}.
                    <template v-slot:action="{ attrs }">
                        <v-btn text v-bind="attrs" @click="snackbar = false">
                            OK
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>

        </v-dialog>
    </v-row>
</template>
  
<script>
import axios from 'axios';

export default {
    props: {
        payload: {
            required: true
        },
        text: {
            type: String,
            required: true
        },
        paragraphId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            snackText: '',
            color: '',
            snackbar: false,
            dialog: false,
            form: {
                words: [{
                    word: '',
                    link: ''
                }],
            }
        }
    },
    methods: {
        setSnackBar(color, text, snack) {
            this.color = color;
            this.snackText = text;
            this.snackbar = snack
        },
        resetForm() {
            this.form = {
                words: [{
                    word: '',
                    link: ''
                }],
            }
        },

        async submit() {
            for (let word of this.form.words) {
                if (word.word === '') {
                    this.setSnackBar('red', 'No se permiten palabras vacías.', true);
                    return;
                }
                const inText = this.text.includes(word.word);

                if (!inText) {
                    this.setSnackBar('red', 'Alguna de las palabras no pertene al párrafo', true);
                    return;
                }

            }

            await axios.put('teacher/text/addHightlightWord/' + this.paragraphId, this.form);
            this.setSnackBar('green', 'Palabra/s guardadas con éxito', true);

        },

        cancel() {
            this.dialog = false;
        }
    },
    created() {
        if (this.payload) {
            const parsed = JSON.parse(this.payload);
            if (parsed.length > 0) this.form.words = parsed

        }
    }
}
</script>
  