<template>
  <v-container fluid>
    <v-row v-if="!loading && activities.length > 0">
      <v-col v-for="(activity, i) in activities" :key="i">
        <card-component :title="`Responder antes del: ${activity.date}`" :description="activity.description"
          image="activity">
          <template>
            <v-row>
              <v-col cols="12">
                <v-btn color="primary" @click="goToText(activity.id)">Ver</v-btn>
              </v-col>
              <v-col cols="12">
                <v-chip color="warning" class="ma-2">
                  Tiempo de lectura: {{ activity.timeLimit ? activity.timeLimit : 'Sin tiempo'}}
                </v-chip>
              </v-col>
            </v-row>
          </template>
        </card-component>
      </v-col>
    </v-row>

    <loading-component v-if="loading" />

    <v-row v-if="!loading && activities.length === 0" style="height: 60vh" align="center">
      <v-col>
        <v-img height="60%" width="60%" :src="require('@/assets/images/undraw_empty_street_sfxm.svg')"></v-img>
        <p class="mt-5">Aún no tiene actividades completadas.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardComponent from "../../../../components/UI/CardComponent";
import LoadingComponent from "../../../../components/UI/LoadingComponent";
export default {
  props: ['loading'],
  components: { LoadingComponent, CardComponent },
  data() {
    return {
    }
  },
  computed: {
    activities() {
      return this.$store.getters['student/getStudentActivitiesReply']
    },
  },
  methods: {
    goToText(id) {
      this.$router.push('/estudiante/finalizada/' + id);
    }
  },

}
</script>