import axios from "axios";

export default {
  async lessons(context) {
    let response = await axios.get("teacher/lesson");
    if (!response.data.ok) {
      const error = new Error(response.message || "Failed auth");
      throw error;
    }
    await context.commit("setLessons", response.data.lessons);
  },
  async texts(context) {
    let response = await axios.get("teacher/text");
    if (!response.data.ok) {
      const error = new Error(response.message || "Failed auth");
      throw error;
    }

    await context.commit("setTexts", response.data.texts);
  },
  async createLesson(context, data) {
    const lessonData = {
      name: data.name,
      year: data.year,
      period: data.period,
      description: data.description,
      image: "ignsanhueza@udec.cl",
    };

    await axios.post("teacher/lesson", lessonData);

    await context.dispatch("lessons");
  },

  async createText(context, data) {
    const textData = {
      title: data.title,
      publicationYear: data.publicationYear,
      author: data.author,
      content: data.content,
      font: data.font,
      type: data.type,
      description: data.description,
      image: "",
    };
    await axios.post("teacher/text", textData);

    await context.dispatch("texts");
    // await context.dispatch('lessons');
  },

  async studentsInLesson(context, data) {
    const response = await axios.get("teacher/lesson_student/" + data);
    await context.commit("setStudentInLessons", response.data.students);
  },
  async createQuestion(context, data) {
    try {
      console.log(data)
      await axios.post("teacher/question", data);
    } catch (e) {
      console.log(e);
    }
  },
  async questions(context, data) {
    try {
      let response = await axios.get("teacher/question/text/" + data);
      await context.commit("setQuestions", response.data.questions);
    } catch (e) {
      console.log(e);
    }
  },
  async question(context, data) {
    let response = await axios.get("teacher/question/" + data);
    if (!response.data.ok) {
      const error = new Error(response.message || "Failed auth");
      throw error;
    }
    await context.commit("setQuestion", response.data.question);
    await context.commit("setAnswers", response.data.question.answer);
  },
  async lesson(context, data) {
    let response = await axios.get("teacher/lesson/" + data);
    await context.commit("setLesson", response.data.lesson);
  },
  async poll(context) {
    let response = await axios.get("teacher/poll");
    if (!response.data.ok) {
      const error = new Error(response.message || "Failed auth");
      throw error;
    }
    await context.commit("setPoll", response.data.polls);
  },
};
