import axios from "axios";

axios.defaults.baseURL = 'https://www.educarteudec.com/laravel/public/api/';
// axios.defaults.baseURL = 'http://localhost:8000/api/'
// axios.defaults.baseURL = 'https://serverpractica.herokuapp.com/'


// axios.defaults.headers.common['token'] = localStorage.getItem('token') || null;

axios.defaults.headers.common = {
    'Authorization': `Bearer ${ localStorage.getItem('token') || null }`,
    'Access-Control-Allow-Origin': '*',
};

