<template>
  <v-container fluid>
    <div v-if="!loading && lessonsData.length === 0" class="mt-10">
      <v-img height="40%" width="40%" src="https://i.imgur.com/79iHNoT.jpg"></v-img>
      <p>Actualmente no pertenece a ninguna asignatura.</p>
    </div>

    <v-row v-if="!loading && lessonsData.length > 0">

      <v-col v-for="(lesson, index) in lessonsData" :key="index">
        <card-component :id="lesson.lesson.id" :title="lesson.lesson.name" :description="lesson.lesson.description"
          image="https://cdn.computerhoy.com/sites/navi.axelspringer.es/public/styles/1200/public/media/image/2019/08/inteligencia-artificial.jpg?itok=2z51IB1L">
          <template>
            <v-btn @click="activities(lesson.lesson.id)" color="primary">Ver Actividades</v-btn>
          </template>
          <template slot="details">
            <v-btn @click="polls(lesson.lesson.id)" color="primary">Encuestas</v-btn>
          </template>
        </card-component>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <v-progress-circular v-if="loading" size="200" :width="3" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import CardComponent from "../../components/UI/CardComponent";

export default {

  data() {
    return {
      loading: false
    }
  },

  components: { CardComponent },
  computed: {
    lessonsData() {
      return this.$store.getters['student/getStudentLessons'];
    }
  },

  methods: {
    async getLessons() {
      await this.$store.dispatch('student/lessonsStudent');
    },
    activities(id) {
      this.$router.push('/estudiante/actividades/' + id);
    },
    polls(id) {
      this.$router.push('/estudiante/encuestas/' + id);
    }
  },
  async created() {
    this.loading = true;
    await this.getLessons();
    this.loading = false;
  }
}
</script>