<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <v-file-input :disabled="loading" :loading="loading" type="file"
            label="Formato excel. Máximo 50 estudiantes por archivo. Formato: primera columna nombre estudiante, segund columna email" @change="addStudents" v-model="excel">
          </v-file-input>
        </v-col>
        <v-col cols="12" v-if="loading">
          <p>Añadiendo estudiantes. Esto pueder tardar unos minutos ...</p>
        </v-col>
      </v-row>

    </v-card-title>
    <v-card-title>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar Estudiantes" single-line hide-details>
      </v-text-field>
    </v-card-title>
    <v-data-table :search="search" v-model="selected" :headers="headers" :items="students" item-key="id"
      class="elevation-1">
      <template v-slot:no-data>
        <p>
          No tiene estudiantes en la asignatura.
        </p>
      </template>
    </v-data-table>

    <div class="text-center ma-2">
      <v-snackbar :color="color" v-model="snackbar">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
        </template>
      </v-snackbar>
    </div>


  </v-card>
</template>

<script>
import axios from 'axios';
export default {
  data: () => ({
    color: 'success',
    text: '',
    snackbar: null,
    loading: false,
    excel: null,
    search: '',
    selected: [],
    headers: [
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
      },
      { text: 'Apellido Paterno', value: 'lastName' },
      { text: 'Apellido Materno', value: 'secondLastName' },
      { text: 'E-mail', value: 'email' },
    ],
  }),

  computed: {
    students() {
      return this.$store.getters['teacher/getStudentsInLesson'];
    },
  },

  created() {
    this.getStudents();
  },

  methods: {
    async addStudents() {
      if (!this.excel) return;
      this.loading = true;
      try {
        const blob = new Blob([this.excel], {
          type: 'data:application/pdf'
        })
        const formData = new FormData();
        formData.append('excel', blob);
        formData.append('id', this.$route.params.id);
        await axios.post('teacher/addStudent', formData);
        this.getStudents();
        this.color = 'success';
        this.text = 'Estudiantes añadidos correctamente.'
        this.snackbar = true;
      } catch (e) {
        console.log(e);
        this.color = 'error';
        this.text = e.response.data.message;
        this.snackbar = true;
      } finally {
        this.loading = false;
        this.excel = null;
      }

    },
    async getStudents() {
      let id = this.$route.params.id;
      await this.$store.dispatch('teacher/studentsInLesson', id);
    },

  },
}
</script>
