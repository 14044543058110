<template>
  <div>
    <student-poll />
  </div>
</template>

<script>
import StudentPoll from '../../../../components/students/StudentPoll.vue';

export default {
  components: { StudentPoll },
  data() {
    return {
      tab: null
    };
  },
  methods: {},

  async created() {

  }
};
</script>
