<template>
  <v-card class="mt-4" width="100vw">
    <v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field readonly label="Dificultad" :value="questionDifficulty"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field readonly label="Tipo" :value="questionType"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Puntaje" type="number" v-model="question.points"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-textarea name="input-7-4" label="Título" v-model="question.title"></v-textarea>
          </v-col>
          <v-col cols="4">
            <v-textarea name="input-7-4" label="Descripción" v-model="question.description"></v-textarea>
          </v-col>
          <v-col cols="4">
            <v-btn class="ma-2 white--text" color="deep-purple accent-4" @click="editQuestion">Editar pregunta</v-btn>
          </v-col>
        </v-row>

        <v-row v-for="(alternative, i) in answers" :key="i" justify="start" align="center">
          <v-col cols="1">
            <v-btn @click="changeCorrectValue(alternative)" :color="alternative.isCorrect ? '#43A047': '#B71C1C'" dark>
              <v-icon dark fab> {{ alternative.isCorrect ? 'mdi-check' : 'mdi-cancel' }} </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="alternative.description" prepend-icon="mdi-format-cancel"
              :label="`Alternativa ${i + 1}`">
            </v-text-field>
          </v-col>
        </v-row>
        <v-btn @click="editAnswer" class="white--text" color="deep-purple accent-4">Editar alternativas</v-btn>

        <v-file-input :loading="loading" @change="addImage" accept="image/jpg" placeholder="Seleccione una Imágen"
          prepend-icon="mdi-camera" v-model="image" label="Añadir imágen formato JPG"></v-file-input>
        <v-img height="40%" width="40%" :src="question.image"></v-img>

      </v-card-text>

    </v-card-text>
    <div class="text-center ma-2">
      <v-snackbar :color="color" v-model="snackbar">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      text: '',
      color: null,
      snackbar: null,
      type: [
        { id: 0, name: "Desarrollo" },
        { id: 1, name: "Selección Múltiple (una respuesta correcta)" },
        { id: 2, name: "Selección Múltiple (varias respuestas correctas)" },
      ],
      image: null,
      id: null,
      loading: false
    }
  },
  methods: {
    async editAnswer() {
      try {
        const data = {
          type: this.question.type,
          answers: this.answers
        }
        await axios.put('teacher/question/answer/' + this.id, data);
        this.setSnackBar('green', 'Alternativas modificadas con éxito.', true);
      } catch (e) {
        this.setSnackBar('red', 'Error modificando pregunta.', true);
      }

    },
    changeCorrectValue(key) {
      if (this.question.type === 1) {
        if (key.isCorrect) return;
        this.answers.map((data) => {
          if (data.id === key.id) return (data.isCorrect = !data.isCorrect);
          if (data.id !== key.id) return (data.isCorrect = false);
        });
      }
      if (this.question.type === 2) {
        this.answers.map((data) => {
          if (data.id === key.id) return (data.isCorrect = !data.isCorrect);
        });
      }
    },
    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack;
    },
    async editQuestion() {
      try {
        await axios.put('teacher/question/' + this.id, this.question);
        this.setSnackBar('green', 'Pregunta modificada con éxito.', true);
      } catch (e) {
        console.log(e)
        this.setSnackBar('red', 'Error modificando pregunta.', true);
      }
    },
    async addImage() {
      if (!this.image) return;
      try {
        this.loading = true;
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const data = {
          image: this.image
        };
        await axios.post('teacher/question/image/' + this.id, data, config);
        await this.$store.dispatch('teacher/question', this.id);

      } catch (e) {
        console.log(e);
      } finally {
        this.image = null;
        this.loading = false;
      }
    },
  },
  computed: {
    question() {
      return this.$store.getters['teacher/getQuestion'];
    },
    answers() {
      return this.$store.getters['teacher/getAnswers'];
    },
    questionType() {
      if (this.question?.type === 0) {
        return 'Desarrollo';
      }
      if (this.question?.type === 1) {
        return 'Selección Múltiple (una respuesta correcta)';
      }
      if (this.question?.type === 2) {
        return 'Selección Múltiple (varias respuestas correctas)';
      }
      return '';
    },
    questionDifficulty() {
      if (this.question?.difficulty === 0) {
        return 'Fácil';
      }
      if (this.question?.difficulty === 1) {
        return 'Intermedio';
      }
      if (this.question?.difficulty === 2) {
        return 'Difícil';
      }
      return '';
    }
  },
  async created() {
    this.id = this.$route.params.id;
    await this.$store.dispatch('teacher/question', this.id);
  }
}
</script>
