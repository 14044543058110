<template>
  <div>

    <v-tabs  v-model="tab">
      <v-tab href="#tab-1">Pendientes</v-tab>
      <v-tab href="#tab-2">Completadas</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <unfinish-activities :loading="loading"/>
      </v-tab-item>

      <v-tab-item value="tab-2">
        <finish-activities :loading="loading"/>
      </v-tab-item>


    </v-tabs-items>


  </div>
</template>
<script>
import UnfinishActivities from "./activities/unfinish/UnfinishActivities";
import FinishActivities from "./activities/finish/FinishActivities";
export default {
  data() {
    return {
      tab: null,
      loading: null,
    }
  },
  components: {FinishActivities, UnfinishActivities},

  async created() {
    this.loading = true;
    let id = this.$route.params.id;
    await this.$store.dispatch('student/activitiesStudent', id);
    this.loading = false;
  }
}
</script>