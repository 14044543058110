<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab href="#tab-1">Texto</v-tab>
      <v-tab href="#tab-2">Palabras Guardadas</v-tab>
      <v-tab href="#tab-3" v-if="finish">Actividades</v-tab>
      <v-tab v-if="!intervalTime" disabled>Tiempo {{ time }}</v-tab>
      <v-tab v-else disabled>Tiempo {{ intervalTime }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card class="mt-4" width="100vw">
          <v-card-title v-if="!hideTitle">
            <v-row>
              <v-col cols="4">
                <v-text-field class="text--accent-4 mx-0 py-0" readonly label="Autor" :value="textData.author" />
              </v-col>
              <v-col cols="8">
                <v-text-field class="text--accent-4 mx-0 py-0" readonly label="Título" :value="textData.title" />
              </v-col>
              <v-col cols="8">
                <v-text-field v-if="textData.font && textData.font.length > 0" class="text--accent-4 mx-0 py-0" readonly
                  label="Fuente" :value="textData.font" />
              </v-col>
              <v-col cols="2">
                <v-text-field v-if="
                  textData.publicationYear &&
                  textData.publicationYear.length > 0
                " class="text--accent-4 mx-0 py-0" readonly label="Año de publicación"
                  :value="textData.publicationYear" />
              </v-col>
              <v-col cols="2">
                <v-text-field v-if="textData.type && textData.type.length > 0" class="text--accent-4 mx-0 py-0" readonly
                  label="Tipo de texto" :value="textData.type" />
              </v-col>
              <v-col cols="10">
                <v-text-field v-if="textData.description && textData.description.length > 0"
                  class="text--accent-4 mx-0 py-0" readonly label="Descripción" :value="textData.description" />
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="hideTitle = true" v-bind="attrs" v-on="on">
                      mdi-eye-off
                    </v-icon>
                  </template>
                  <span>Esconder Info</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>

          <v-tooltip bottom v-if="hideTitle">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="hideTitle = false" v-bind="attrs" v-on="on">
                mdi-eye
              </v-icon>
            </template>
            <span>Mostrar Info</span>
          </v-tooltip>

          <v-card-title v-if="!finish" style="background-color: aliceblue">
            <v-row class="mt-3" align="center" justify="space-around">
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="changeTextSize('minus')" block color="primary" dark v-bind="attrs" v-on="on">
                      Aa-
                    </v-btn>
                  </template>
                  <span>Disminuir Tamaño Fuente</span>
                </v-tooltip>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="changeTextSize('initial')" block color="primary" dark v-bind="attrs" v-on="on">
                      Aa
                    </v-btn>
                  </template>
                  <span>Fuente Normal</span>
                </v-tooltip>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="changeTextSize('plus')" block color="primary" dark v-bind="attrs" v-on="on">
                      Aa+
                    </v-btn>
                  </template>
                  <span>Aumentar Tamaño Fuente</span>
                </v-tooltip>
              </v-col>

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="raeSearch" block color="primary" dark v-bind="attrs" v-on="on">
                      <v-icon dark left> mdi-book-alphabet </v-icon>
                      Buscar
                    </v-btn>
                  </template>
                  <span>Buscar Palabra Diccionario</span>
                </v-tooltip>
              </v-col>

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="setHighLight" block color="primary" dark v-bind="attrs" v-on="on">
                      <v-icon dark left> mdi-content-save </v-icon>
                      Guardar
                    </v-btn>
                  </template>
                  <span>Guardar Palabra</span>
                </v-tooltip>
              </v-col>

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="highLightWord" block color="primary" dark v-bind="attrs" v-on="on">
                      Resaltar
                    </v-btn>
                  </template>
                  <span>Resaltar Palabra/s</span>
                </v-tooltip>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="noHighLightWord" block color="primary" dark v-bind="attrs" v-on="on">
                      Quitar
                    </v-btn>
                  </template>
                  <span>Quitar Resaltado Palabra/s</span>
                </v-tooltip>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn block color="success" @click="finishRead">
                  Terminar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-container fluid ref="target" v-if="textData.type !== 'Minijuego'">
              <v-row class="text--primary" v-for="(data, i) in textParagraphs" :key="i">
                <v-col cols="12" v-if="data.subTitle">
                  <p id="test" class="text-justify" :style="{ fontSize: textSize }" v-html="data.subTitle"></p>
                </v-col>
                <v-col cols="11">
                  <div class="text-justify" :style="{ fontSize: textSize }" v-html="data.description"></div>

                  <v-text-field v-if="paragraphQuestion[i] && paragraphQuestion[i].visible" :disabled="finish"
                    @click:prepend="paragraphQuestion[i].visible = false" v-model="paragraphQuestion[i].answer"
                    :prepend-icon="
                      paragraphQuestion[i].answer === ''
                        ? 'mdi-close'
                        : 'mdi-check-all'
                    " :label="`Pregunta Párrafo ${i + 1}`" />
                </v-col>
                <v-col cols="1" v-if="textData.type !== 'Diagnóstico'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon @click="
                        paragraphQuestion[i].visible =
                        !paragraphQuestion[i].visible
                      " :color="
  paragraphQuestion[i] &&
    paragraphQuestion[i].answer.length > 0
    ? 'success'
    : 'orange'
" v-bind="attrs" v-on="on">
                        mdi-comment-question
                      </v-icon>
                    </template>
                    <span>{{
                        paragraphQuestion[i] &&
                          paragraphQuestion[i].answer.length > 0
                          ? "Pregunta Realizada."
                          : "Realizar Pregunta."
                    }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-img height="100%" width="50%" :src="data.image"></v-img>

                </v-col>
              </v-row>
            </v-container>
            <v-container v-else>

              <p class="text-justify"><strong>Instrucciones: seleccione los párrafos para asignarle un orden que le haga
                  sentido.</strong></p>
              <v-row class="text--primary" v-if="!finish">
                <draggable :list="textParagraphs" :disabled="finish" class="list-group" ghost-class="ghost"
                  :move="checkMove" @start="dragging = true" @end="dragging = false">
                  <v-col cols="12" class="list-group-item" v-for="(element, i) in textParagraphs" :key="i">
                    <p class="text-justify" :style="{ fontSize: textSize, cursor: 'move' }"
                      v-html="element.description"></p>
                  </v-col>
                </draggable>

              </v-row>

              <v-row class="text--primary" v-for="(element, i) in textParagraphs" :key="i" v-else>
                <v-col cols="6" class="list-group-item">
                  <p class="text-justify"
                    :style="{ fontSize: textSize, color: correctOrder[i] === element.id ? 'green' : 'red' }"
                    v-html="element.description">
                  </p>
                </v-col>

                <v-col cols="6" class="list-group-item">
                  <p class="text-justify" :style="{ fontSize: textSize }"
                    v-html="textParagraphs.find(el => el.id === correctOrder[i]).description">
                  </p>
                </v-col>

              </v-row>

              <!-- <v-row class="text--primary" v-for="(data, i) in textParagraphs" :key="i">
                <v-col cols="11">
                  <p class="text-justify" :style="{ fontSize: textSize }" v-html="data.description"></p>

                  <v-text-field v-if="paragraphQuestion[i] && paragraphQuestion[i].visible" :disabled="finish"
                    @click:prepend="paragraphQuestion[i].visible = false" v-model="paragraphQuestion[i].answer"
                    :prepend-icon="
                      paragraphQuestion[i].answer === ''
                        ? 'mdi-close'
                        : 'mdi-check-all'
                    " :label="`Pregunta Párrafo ${i + 1}`" />
                </v-col>
                <v-col cols="1" v-if="textData.type !== 'Diagnóstico'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon @click="
                        paragraphQuestion[i].visible =
                        !paragraphQuestion[i].visible
                      " :color="
                        paragraphQuestion[i] &&
                          paragraphQuestion[i].answer.length > 0
                          ? 'success'
                          : 'orange'
                      " v-bind="attrs" v-on="on">
                        mdi-comment-question
                      </v-icon>
                    </template>
                    <span>{{
                    paragraphQuestion[i] &&
                    paragraphQuestion[i].answer.length > 0
                    ? "Pregunta Realizada."
                    : "Realizar Pregunta."
                    }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-img height="100%" width="50%" :src="data.image"></v-img>

                </v-col>
              </v-row> -->
            </v-container>
          </v-card-text>

          <v-snackbar :color="color" v-model="snackbar">
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-2">
        <words-table :words="savedWords" />
      </v-tab-item>

      <v-tab-item value="tab-3">
        <student-activity :order="textData.type === 'Minijuego' ? textParagraphs.map(el => el.id) : []"
          :forceFinish="forceFinish" :time="time" :words="savedWords" :paragraph-questions="paragraphQuestion"
          :highLights="highLights" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";
import WordsTable from "../../../../components/students/WordsTable";
import StudentActivity from "../../../../components/students/StudentActivity";
import Swal from "sweetalert2";
import draggable from 'vuedraggable';

export default {
  components: { StudentActivity, WordsTable, draggable },
  watch: {
    $route(to, from) {
      console.log(to, from)
      this.show = false;
    }
  },
  data() {
    return {
      correctOrder: [],
      dragging: false,
      forceFinish: false,
      interval: null,
      color: "red",
      text: "",
      snackbar: false,
      hideTitle: false,
      savedWords: [],
      finish: false,
      intervalTime: null,
      tab: null,
      textData: [],
      textParagraphs: [],
      initial: 17,
      auxInitial: 17,
      textSize: "17px",
      highLights: [],
      canHighLight: false,
      time: 0,
      activityId: null,
      paragraphQuestion: [],
      loading: null,
      questionsFromText: [],
    };
  },
  methods: {
    checkMove(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    async finishRead() {
      await Swal.fire({
        title: "¿Desea finalizar la lectura?",
        text: "Pasará a responder preguntas",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí!",
        cancelButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Swal.fire(
            "Completada!",
            "La lectura ha sido completada..",
            "success"
          );
          this.finish = true;
          this.tab = "tab-3";
          // clearInterval(this.interval);
        }
      });
    },
    setHighLight() {
      // if (highlight === "") {
      //   this.snackbar = true;
      //   this.color = "red";
      //   this.text = "Debe seleccionar una palabra.";
      // }
      this.canHighLight = true;
      this.saveWord();
    },
    changeTextSize(statement) {
      if (statement === "plus") {
        this.initial++;
        this.textSize = `${this.initial}px`;
      }

      if (statement === "minus") {
        if (this.initial > 1) {
          this.initial--;
          this.textSize = `${this.initial}px`;
        }
      }

      if (statement === "initial") {
        this.initial = this.auxInitial;
        this.textSize = `${this.initial}px`;
      }
    },

    async getText() {
      try {
        const response = await axios.get(
          "student/activity/text/" + this.activityId
        );
        const { text, paragraph } = response.data;
        this.textData = text;
        this.textParagraphs = paragraph;
      } catch (e) {
        console.log(e);
      }
    },

    searchWord(word) {
      return this.savedWords.find((data) => data === word);
    },

    noHighLightWord() {
      let word = window.getSelection().toString().trim();
      let i;
      if (word.length === 0) {
        this.snackbar = true;
        this.color = "red";
        this.text = "Debe seleccionar palabras previamente destadas.";
        return;
      }

      for (let i = 0; i < this.textParagraphs.length; i++) {
        this.textParagraphs[i].description = this.textParagraphs[
          i
        ].description.replaceAll(
          word,
          '<strong style="background-color: white; font-weight: normal">' +
          word +
          "</strong>"
        );
      }
      this.highLights.find((item, index) => {
        if (item === word) i = index;
      });

      this.highLights.splice(i, 1);
    },
    highLightWord() {
      console.log('highLightWord')
      let word = window.getSelection().toString().trim();
      if (word.length === 0) {
        this.snackbar = true;
        this.color = "red";
        this.text = "Debe seleccionar una o varias palabras.";
        return;
      }

      for (let i = 0; i < this.textParagraphs.length; i++) {
        this.textParagraphs[i].description = this.textParagraphs[
          i
        ].description.replaceAll(
          word,
          '<strong style="background-color: yellow">' + word + "</strong>"
        );
      }

      if (!this.highLights.find((val) => val === word))
        this.highLights.push(word);
    },

    raeSearch() {
      let word = window.getSelection().toString().trim();
      if (word.length === 0) {
        this.snackbar = true;
        this.color = "red";
        this.text = "Debe seleccionar una palabra.";
        return;
      }
      window.open(
        `https://dle.rae.es/${word}?m=form`,
        "_blank",
        "width=600,height=600"
      );
    },
    testSPan() {
      console.log('span');
    },
    saveWord() {
      let highlight = window.getSelection().toString().trim();
      if (highlight.trim() !== "") {
        let count = 0;

        const words = highlight.split(" ");

        words.find((data) => {
          if (data === "") count++;
        });

        if (this.canHighLight && words.length - count === 1) {
          if (this.searchWord(highlight)) {
            this.canHighLight = false;
            this.snackbar = true;
            this.color = "orange";
            this.text = "Palabra: " + highlight + " ya está guardada";
            return;
          }

          this.savedWords.push(highlight);
          this.snackbar = true;
          this.text = "Palabra guardada con éxito.";
          this.color = "green";
          this.canHighLight = false;
        }
      }
      this.canHighLight = false;
    },
  },

  async created() {
    let firstTime = true;
    let timeFinish = false;
    this.activityId = this.$route.params.id;
    if (localStorage.getItem('activity_' + this.activityId)) {
      let time = localStorage.getItem('activity_' + this.activityId);
      time = JSON.parse(time);
      this.time = time.timePased;

      if (time.timePased >= time.limitTime) {
        localStorage.removeItem('activity_' + this.activityId);
        timeFinish = true;
        this.finish = true;
        this.tab = "tab-3";
        clearInterval(this.interval);
        this.forceFinish = true;
      }
    }


    await this.getText();

    this.loading = true;
    await this.textParagraphs.forEach((paragraph) => {
      this.correctOrder.push(paragraph.id)
      this.paragraphQuestion.push({
        id: paragraph.id,
        answer: "",
        textId: this.textData.id,
        visible: false,
      });
    });
    this.textParagraphs = this.textParagraphs.map((el) => {
      const parsed = JSON.parse(el.payload);
      if (parsed) {
        parsed.forEach(str => {
          el.description = el.description.replaceAll(str.word, `<a href="${str.link}" target='_blank'>${str.word}</a>`)

        })
      }

      return el;
    })
    if (this.textData.type === 'Minijuego') {
      this.textParagraphs.sort(function () { return 0.5 - Math.random() });
    }


    this.loading = false;

    document.addEventListener("mouseup", (event) => {
      if (
        event.target === this.$refs.target ||
        event.target.contains(this.$refs.target)
      )
        this.saveWord();
    });

    if (!timeFinish) {
      this.interval = setInterval(async () => {
        if (this.$router.currentRoute.params.id !== this.activityId) {
          clearInterval(this.interval);
        }
        if (localStorage.getItem('activity_' + this.activityId)) {
          let time = localStorage.getItem('activity_' + this.activityId);
          time = JSON.parse(time);
          const data = {
            limitTime: time.limitTime,
            timePased: 0
          }
          this.time++
          data.timePased = this.time;
          localStorage.setItem('activity_' + this.activityId, JSON.stringify(data));

          if (data.timePased >= data.limitTime && firstTime) {
            firstTime = false;
            this.finish = true;
            this.tab = "tab-3";
            this.forceFinish = true;
            clearInterval(this.interval);
          }
        } else {
          this.time++
        }
      }, 1000);
    }

  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
