<template>
  <v-card
      class="mx-auto"
      max-width="400"
      height="90%"
  >
    <v-img
        class="white--text align-end"
        height="40%"
        width="100%"
        :src="require(`@/assets/images/${cardImage}`)"
    >
    </v-img>

    <v-card-title>{{ title }}</v-card-title>

    <v-card-text class="text--primary">
      <div>{{ description }}</div>
    </v-card-text>

    <v-card-actions>
      <v-row>
        <v-col cols="12">
          <slot>

          </slot>
        </v-col>
        <v-col cols="12">
          <slot name="details">

          </slot>
        </v-col>
      </v-row>



    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    cardImage() {
      if(this.image === 'question') return 'question.jpg'
      if(this.image === 'text') return 'text.jpg'
      if(this.image === 'lesson') return 'lesson.jpg'
      if(this.image === 'activity') return 'activity.jpg'
      return 'question.jpg';
    }
  },
  props: ["title", "description", "image", "id"],
  methods: {
  }
}
</script>