<template>
  <v-container fluid>
    <div v-if="!loading && this.textsData.length === 0">
      <v-img
          width="60%"
          :src="require('@/assets/images/nodata.jpg')"
      ></v-img>
      <p>Actualmente no posee Textos Creados</p>
      <text-form class="mt-3"/>
    </div>

    <v-row v-if="!loading && this.textsData.length > 0">
      <v-autocomplete
          @change="textDetailsBar"
          :items="textsData"
          item-text="title"
          outlined
          dense
          label="Buscar Texto"
          return-object
      ></v-autocomplete>
    </v-row>
    <v-row v-if="!loading && this.textsData.length > 0">

      <v-col v-for="(text, index) in showableArray" :key="index">
        <card-component
            :title="text.title[0].toUpperCase() + text.title.slice(1)"
            :description="text.description[0].toUpperCase() + text.description.slice(1)"
            image="text"
        >
          <template>
            <question-form :text-id="text.id"/>
          </template>
          <template v-slot:details>
            <v-btn
                @click="textDetails(text.id)"
                color="success"
            >
              Ver Detalles
            </v-btn>
          </template>
        </card-component>
      </v-col>
    </v-row>

    <loading-component v-if="loading"/>

    <v-container fluid v-if="!loading && textsData.length > 0">
      <v-btn
          v-for="(index, i) in paginate"
          :key="i"
          @click="current_page = index - 1"
          elevation="3"
          color="primary"
          fab
          style="margin-right: 10px"
      >
        {{ index }}
      </v-btn>
    </v-container>

  </v-container>
</template>

<script>

import CardComponent from "../../../components/UI/CardComponent";
import TextForm from "../../../components/teacher/forms/TextForm";
import QuestionForm from "../../../components/teacher/forms/QuestionForm";
import LoadingComponent from "../../../components/UI/LoadingComponent";

export default {
  components: {
    LoadingComponent,
    CardComponent ,
    TextForm,
    QuestionForm
  },
  computed: {
    textsData() {
      return this.$store.getters['teacher/getTexts'];
    },
    paginate() {
      return  Math.ceil((this.textsData.length / this.items_per_page));
    },
    showableArray() {
      return this.textsData.slice(this.current_page*this.items_per_page, this.items_per_page + this.current_page*this.items_per_page);
    }
  },
  data() {
    return {
      loading: null,
      items_per_page: 6,
      current_page: 0,
    }
  },
  methods: {
    textDetailsBar(text) {
      this.$router.push('/profe/textos/' + text.id);
    },
    textDetails(id) {
      this.$router.push('/profe/textos/' + id);
    },
    async getTexts() {
      await this.$store.dispatch('teacher/texts');
    }

  },
  async created() {
    this.loading = true;
    await this.getTexts();
    this.loading = false;
  },
}
</script>
