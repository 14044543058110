<template>
  <div>

    <v-tabs v-model="tab">
      <v-tab href="#tab-1">Preguntas</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">

      <v-data-table  :headers="headers" :items="poll"
        item-key="id"  class="elevation-1">
      </v-data-table>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      headers: [],
      pollId: null,
      poll: [],
      answer: [],
      tab: null,
    }
  },
  methods: {
    async getPoll() {
      const response = await axios.get('teacher/poll/' + this.pollId);
      this.poll = response.data.poll.question;
      this.answer = response.data.poll.answer;
      this.headers = this.answer.map(el => {
        return {
          text: el.description,
          align: 'start',
          sortable: false,
        }
      });
      this.headers.unshift(
        {
          text: 'Preguntas',
          align: 'start',
          value: 'description',
          sortable: false,
        },
        {
          text: 'Tipo',
          align: 'start',
          value: 'type',
          sortable: false,
        },
      )
    },
  },
  async created() {
    this.pollId = this.$route.params.id;
    await this.getPoll();
  }
}
</script>
