<template>
  <div>
    <v-tabs v-model="tab" class="mt-7">
      <v-tab v-for="(_, i) in questions" :href="`#${i}`" :key="i">Pregunta {{ i + 1 }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(question, index) in questions" :key="index" :value="`${index}`">
        <v-card :key="index" class="mt-4" width="100vw" height="100vh" v-if="!loading">
          <v-form @submit.prevent="submit">
            <v-card-text>
              <v-card-title>
                <p>Instrucciones: {{ description }}</p>
              </v-card-title>
              <v-card-title>
                <p>
                  {{ question.description }}
                </p>
              </v-card-title>

              <v-container fluid>
                <v-radio-group>
                  <v-radio :disabled="finish" v-for="(alternative, i) in answers" :key="i"
                    :label="alternative.description" :value="alternative"
                    @change="selectAlternative(alternative, question)">
                    {{ alternative.description }}
                  </v-radio>
                </v-radio-group>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="tab !== '0'" @click="tab = `${parseInt(tab) - 1}`" text color="deep-purple accent-4">
                Anterior
              </v-btn>
              <v-btn v-if="tab !== `${questions.length - 1}`" @click="tab = `${parseInt(tab) + 1}`" text
                color="deep-purple accent-4">
                Siguiente
              </v-btn>
              <p v-if="!canSendForm && tab === `${questions.length - 1}`" class="text-justify mt-4" style="color: red">
                Debe responder todas las preguntas para enviar formulario.
              </p>
              <v-btn v-if="
                tab === `${questions.length - 1}` && !finish && canSendForm
              " @click="submit" text color="deep-purple accent-4">
                Terminar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      description: '',
      studentReply: [],
      tab: null,
      answers: [],
      questions: [],
      finish: false,
      loading: false,
      points: 0,
      totalPoints: 0,
    };
  },
  computed: {
    canSendForm() {
      return this.studentReply.length === this.questions.length;
    },
  },
  methods: {
    selectAlternative(answer, question) {
      const data = {
        questionId: question.id,
        answerId: answer.id,
        answer,
        question
      }
      const findQuestion = this.studentReply.findIndex(el => el.questionId === question.id)
      if (findQuestion !== -1) {
        this.studentReply[findQuestion].answerId = answer.id
        this.studentReply[findQuestion].answer = answer
      } else {
        this.studentReply.push(data);
      }
    },
    async submit() {
      this.points = this.studentReply.reduce((acc, el) => acc + el.answer.points , 0);
      let id = this.$route.params.id;

      await Swal.fire({
        title: "¿Desea terminar la evaluación?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí!",
        cancelButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Swal.fire(
            "Completada!",
            "Las respuestas han sido contestadas",
            "success"
          );
          this.tab = 0;
          this.finish = true;
          await axios.post("student/poll_reply/" + id, {
            answer: JSON.stringify(this.studentReply),
            points: this.points,
          });
          this.$router.push('/estudiante/asignaturas')
        }
      });
    },
  },
  async created() {
    this.loading = true;
    let id = this.$route.params.id;
    try {
      const response = await axios.get("student/poll_activity/" + id);
      this.answers = response.data.questions.poll.answer;
      this.questions = response.data.questions.poll.question;
      this.description = response.data.questions.description;
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>