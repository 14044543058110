var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v("Texto")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v("Palabras Guardadas")]),(_vm.finish)?_c('v-tab',{attrs:{"href":"#tab-3"}},[_vm._v("Actividades")]):_vm._e(),(!_vm.intervalTime)?_c('v-tab',{attrs:{"disabled":""}},[_vm._v("Tiempo "+_vm._s(_vm.time))]):_c('v-tab',{attrs:{"disabled":""}},[_vm._v("Tiempo "+_vm._s(_vm.intervalTime))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('v-card',{staticClass:"mt-4",attrs:{"width":"100vw"}},[(!_vm.hideTitle)?_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Autor","value":_vm.textData.author}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Título","value":_vm.textData.title}})],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.textData.font && _vm.textData.font.length > 0)?_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Fuente","value":_vm.textData.font}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[(
                  _vm.textData.publicationYear &&
                  _vm.textData.publicationYear.length > 0
                )?_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Año de publicación","value":_vm.textData.publicationYear}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.textData.type && _vm.textData.type.length > 0)?_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Tipo de texto","value":_vm.textData.type}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[(_vm.textData.description && _vm.textData.description.length > 0)?_c('v-text-field',{staticClass:"text--accent-4 mx-0 py-0",attrs:{"readonly":"","label":"Descripción","value":_vm.textData.description}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){_vm.hideTitle = true}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-off ")])]}}],null,false,2397807654)},[_c('span',[_vm._v("Esconder Info")])])],1)],1)],1):_vm._e(),(_vm.hideTitle)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){_vm.hideTitle = false}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,false,3573705423)},[_c('span',[_vm._v("Mostrar Info")])]):_vm._e(),(!_vm.finish)?_c('v-card-title',{staticStyle:{"background-color":"aliceblue"}},[_c('v-row',{staticClass:"mt-3",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"xl":"1","lg":"2","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"primary","dark":""},on:{"click":function($event){return _vm.changeTextSize('minus')}}},'v-btn',attrs,false),on),[_vm._v(" Aa- ")])]}}],null,false,456747329)},[_c('span',[_vm._v("Disminuir Tamaño Fuente")])])],1),_c('v-col',{attrs:{"xl":"1","lg":"2","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"primary","dark":""},on:{"click":function($event){return _vm.changeTextSize('initial')}}},'v-btn',attrs,false),on),[_vm._v(" Aa ")])]}}],null,false,2677927454)},[_c('span',[_vm._v("Fuente Normal")])])],1),_c('v-col',{attrs:{"xl":"1","lg":"2","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"primary","dark":""},on:{"click":function($event){return _vm.changeTextSize('plus')}}},'v-btn',attrs,false),on),[_vm._v(" Aa+ ")])]}}],null,false,3394737937)},[_c('span',[_vm._v("Aumentar Tamaño Fuente")])])],1),_c('v-col',{attrs:{"xl":"1","lg":"2","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"primary","dark":""},on:{"click":_vm.raeSearch}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-book-alphabet ")]),_vm._v(" Buscar ")],1)]}}],null,false,2066464761)},[_c('span',[_vm._v("Buscar Palabra Diccionario")])])],1),_c('v-col',{attrs:{"xl":"1","lg":"2","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"primary","dark":""},on:{"click":_vm.setHighLight}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" Guardar ")],1)]}}],null,false,162644503)},[_c('span',[_vm._v("Guardar Palabra")])])],1),_c('v-col',{attrs:{"xl":"1","lg":"2","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"primary","dark":""},on:{"click":_vm.highLightWord}},'v-btn',attrs,false),on),[_vm._v(" Resaltar ")])]}}],null,false,1988612968)},[_c('span',[_vm._v("Resaltar Palabra/s")])])],1),_c('v-col',{attrs:{"xl":"1","lg":"2","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"primary","dark":""},on:{"click":_vm.noHighLightWord}},'v-btn',attrs,false),on),[_vm._v(" Quitar ")])]}}],null,false,1176398445)},[_c('span',[_vm._v("Quitar Resaltado Palabra/s")])])],1),_c('v-col',{attrs:{"xl":"1","lg":"2","sm":"6","md":"3"}},[_c('v-btn',{attrs:{"block":"","color":"success"},on:{"click":_vm.finishRead}},[_vm._v(" Terminar ")])],1)],1)],1):_vm._e(),_c('v-card-text',[(_vm.textData.type !== 'Minijuego')?_c('v-container',{ref:"target",attrs:{"fluid":""}},_vm._l((_vm.textParagraphs),function(data,i){return _c('v-row',{key:i,staticClass:"text--primary"},[(data.subTitle)?_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-justify",style:({ fontSize: _vm.textSize }),attrs:{"id":"test"},domProps:{"innerHTML":_vm._s(data.subTitle)}})]):_vm._e(),_c('v-col',{attrs:{"cols":"11"}},[_c('div',{staticClass:"text-justify",style:({ fontSize: _vm.textSize }),domProps:{"innerHTML":_vm._s(data.description)}}),(_vm.paragraphQuestion[i] && _vm.paragraphQuestion[i].visible)?_c('v-text-field',{attrs:{"disabled":_vm.finish,"prepend-icon":_vm.paragraphQuestion[i].answer === ''
                        ? 'mdi-close'
                        : 'mdi-check-all',"label":("Pregunta Párrafo " + (i + 1))},on:{"click:prepend":function($event){_vm.paragraphQuestion[i].visible = false}},model:{value:(_vm.paragraphQuestion[i].answer),callback:function ($$v) {_vm.$set(_vm.paragraphQuestion[i], "answer", $$v)},expression:"paragraphQuestion[i].answer"}}):_vm._e()],1),(_vm.textData.type !== 'Diagnóstico')?_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.paragraphQuestion[i] &&
    _vm.paragraphQuestion[i].answer.length > 0
    ? 'success'
    : 'orange'},on:{"click":function($event){_vm.paragraphQuestion[i].visible =
                        !_vm.paragraphQuestion[i].visible}}},'v-icon',attrs,false),on),[_vm._v(" mdi-comment-question ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.paragraphQuestion[i] && _vm.paragraphQuestion[i].answer.length > 0 ? "Pregunta Realizada." : "Realizar Pregunta."))])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{attrs:{"height":"100%","width":"50%","src":data.image}})],1)],1)}),1):_c('v-container',[_c('p',{staticClass:"text-justify"},[_c('strong',[_vm._v("Instrucciones: seleccione los párrafos para asignarle un orden que le haga sentido.")])]),(!_vm.finish)?_c('v-row',{staticClass:"text--primary"},[_c('draggable',{staticClass:"list-group",attrs:{"list":_vm.textParagraphs,"disabled":_vm.finish,"ghost-class":"ghost","move":_vm.checkMove},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}}},_vm._l((_vm.textParagraphs),function(element,i){return _c('v-col',{key:i,staticClass:"list-group-item",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-justify",style:({ fontSize: _vm.textSize, cursor: 'move' }),domProps:{"innerHTML":_vm._s(element.description)}})])}),1)],1):_vm._l((_vm.textParagraphs),function(element,i){return _c('v-row',{key:i,staticClass:"text--primary"},[_c('v-col',{staticClass:"list-group-item",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-justify",style:({ fontSize: _vm.textSize, color: _vm.correctOrder[i] === element.id ? 'green' : 'red' }),domProps:{"innerHTML":_vm._s(element.description)}})]),_c('v-col',{staticClass:"list-group-item",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-justify",style:({ fontSize: _vm.textSize }),domProps:{"innerHTML":_vm._s(_vm.textParagraphs.find(function (el) { return el.id === _vm.correctOrder[i]; }).description)}})])],1)})],2)],1),_c('v-snackbar',{attrs:{"color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" OK ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('words-table',{attrs:{"words":_vm.savedWords}})],1),_c('v-tab-item',{attrs:{"value":"tab-3"}},[_c('student-activity',{attrs:{"order":_vm.textData.type === 'Minijuego' ? _vm.textParagraphs.map(function (el) { return el.id; }) : [],"forceFinish":_vm.forceFinish,"time":_vm.time,"words":_vm.savedWords,"paragraph-questions":_vm.paragraphQuestion,"highLights":_vm.highLights}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }