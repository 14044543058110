<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col justify="center">
        <v-card>
          <v-form @submit.prevent="submit" ref="form" lazy-validation>
            <v-card-text>
              <v-autocomplete @change="searchQuestions(form.text)" v-model="form.text" :items="texts" item-text="title"
                prepend-icon="mdi-format-title" label="Seleccione un Texto" required return-object>
                <template v-slot:item="{ item }">
                  <span>
                    {{ item.title }}
                  </span>
                  <v-chip color="primary" v-if="item.type==='Minijuego'">
                    <span>{{ item.type }}</span>
                  </v-chip>
                </template>
              </v-autocomplete>
              <v-container fluid v-if="form.text && form.text.type === 'Minijuego'">
                <p class="text-justify">
                  Info: Los textos etiquetados como <strong>Minijuego</strong> pueden o no llevar preguntas asociadas.
                </p>
                <p class="text-justify">
                  El minijuego asociado a esta actividad consiste en ordenar los párrafos del texto seleccionado, que
                  son presentados al estudiante de manera desordenada.
                </p>
              </v-container>
              <v-autocomplete clearable v-model="form.pre" :items="activities" item-text="description"
                prepend-icon="mdi-format-title" label="Pre-requisito" required return-object>
              </v-autocomplete>
              <date-component @date="form.limit = $event" />

              <v-container fluid v-if="form.text && questions.length > 0">
                <p class="text-justify">
                  Seleccione las preguntas que desea añadir:
                </p>
                <v-data-table hide-default-footer disable-pagination v-model="selected" show-select :items="questions"
                  item-key="id" :headers="headers">
                </v-data-table>
              </v-container>
              <v-container v-if="questions.length === 0 && form.text">
                <p class="text-justify red--text">
                  El texto no posee ninguna pregunta
                </p>
              </v-container>
              <v-textarea prepend-icon="mdi-format-text-variant" v-model="form.description" auto-grow filled
                color="deep-purple" label="Descripción" rows="3"></v-textarea>
              <v-text-field type="number" v-model="form.limitTime" prepend-icon="mdi-counter"
                label="Tiempo límite minutos (Opcional)"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="isFormValid" color="primary" type="submit">
                Crear Asignación
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateComponent from "../../UI/DateComponent";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: {
    lessonId: {
      type: String,
    },
  },
  components: { DateComponent },
  computed: {
    texts() {
      return this.$store.getters["teacher/getTexts"];
    },
    questions() {
      return this.$store.getters["teacher/getQuestions"];
    },
    isFormValid() {
      if (this.form.text && this.form.text.type === 'Minijuego') {
        return !(!this.form.text ||
          this.form.description === "" ||
          !this.form.limit);
      } else {
        return !(this.selected.length === 0 ||
          !this.form.text ||
          this.form.description === "" ||
          !this.form.limit);
      }
    },
  },
  data() {
    return {
      headers: [],
      activities: [],
      selected: [],
      form: {
        lessonId: parseInt(this.lessonId),
        text: null,
        limit: null,
        description: "",
        pre: null,
        limitTime: null
      },
    };
  },
  methods: {
    async getActivities() {
      const response = await axios.get('teacher/activityTeacher/' + this.lessonId)
      const { activity } = response.data;
      this.activities = activity;
    },
    async searchQuestions({ id }) {
      await this.$store.dispatch("teacher/questions", id);
      this.selected = this.questions;
    },
    async getText() {
      await this.$store.dispatch("teacher/texts");
    },
    async submit() {
      await Swal.fire({
        title: "¿Desea crear la activad?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí!",
        cancelButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Swal.fire("Creada", "Activdad creada con éxito", "success");
          let activityId;

          let data = {
            lessonId: this.form.lessonId,
            textId: this.form.text.id,
            date: this.form.limit,
            description: this.form.description,
            pre: this.form.pre ? parseInt(this.form.pre.id) : null,
            limitTime: this.form.limitTime ? parseInt(this.form.limitTime) : null,
          };
          const response = await axios.post("teacher/activity", data);
          const { id } = response.data.activity;
          activityId = id;
          if (this.selected.length > 0) {
            try {
              await axios.post("teacher/activity/question", {
                activityId,
                questionId: this.selected,
              });

            } catch (e) {
              console.log(e);
            }
          }

          this.resetForm();
          await this.getText();


        }
      });
    },
    resetForm() {
      this.selected = [];
      this.form = {
        lessonId: parseInt(this.lessonId),
        text: null,
        limit: null,
        description: "",
        pre: null,
        limitTime: null
      };
    },
  },
  async created() {
    await this.getActivities();
    await this.getText();
    this.headers.unshift(
      {
        text: 'Título pregunta',
        align: 'start',
        value: 'title',
        sortable: false,
      },
      {
        text: 'Descripción',
        align: 'start',
        value: 'description',
        sortable: false,
      },
    )
  },
};
</script>