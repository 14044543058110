<template>
  <v-card
      elevation="2"
      class="mt-4"
      width="100vw"
      height="100vh"
  >
    <v-card-text>
      <v-text-field
          @change="edit=true"
          label="Nombre Asignatura"
          v-model="name"
      />
      <v-text-field
          @change="edit=true"
          label="Descripción"
          v-model="description"
      />
      <v-text-field
          @change="edit=true"
          type="number"
          label="Año"
          v-model="year"
      />
      <v-text-field
          @change="edit=true"
          label="Período"
          v-model="period"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
          :disabled="!edit"
          text
          color="deep-purple accent-4"
      >
        Guardar Cambios
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
      name: '',
      description: '',
      image: '',
      period: '',
      year: '',
      edit: false
    }
  },
  methods: {
    async getLesson() {
      const id = this.$route.params.id;
      await this.$store.dispatch('teacher/lesson', id);
      const lesson = this.$store.getters['teacher/getLesson'];
      this.name = lesson.name;
      this.description = lesson.description;
      this.image = lesson.image;
      this.period = lesson.period;
      this.year= lesson.year;
    },
  },
  async created() {
    await this.getLesson();
  }
}
</script>
