export default {
    getStudentLessons(state) {
        return state.studentLessons;
    },
    getStudentTexts(state) {
        return state.studentTexts;
    },
    getStudentActivitiesReply(state) {
        return state.studentActivitiesReply;
    },
    getStudentActivitiesNoReply(state) {
        return state.studentActivitiesNoReply;
    },
    getStudentPollsReply(state) {
        return state.studentPollsReply;
    },
    getStudentPollsNoReply(state) {
        return state.studentPollsNoReply;
    },
}
