<template>
  <v-row justify="center">
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="searchStudents" small color="primary" v-bind="attrs" v-on="on">
          Ver Estudiantes
        </v-btn>

      </template>

      <v-container fluid>
        <v-row justify="center">
          <v-col justify="center">
            {{ loading }}
            <v-card v-if="loading">
              <v-progress-linear size="200" :width="3" color="primary" indeterminate>
              </v-progress-linear>
            </v-card>
            <v-card v-if="studentsNames.length === 0 && !loading">
              <v-card-text>
                <p>Ningún Estudiante ha completado la actividad</p>
              </v-card-text>
            </v-card>
            <v-card v-if="studentsNames.length > 0 && !loading">
              <v-card-text>
                <v-autocomplete @change="studentDetails" label="Estudiantes" :items="studentsNames" item-text="name"
                  return-object />
              </v-card-text>

              <v-card-actions>
                <v-btn text>
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text>
                  Ver Respuestas
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </v-row>
</template>

<script>

import axios from "axios";

export default {
  props: {
    activityId: {
      type: Number,
    },
    activityType: {
      type: String,
    }
  },

  data() {
    return {
      loading: false,
      dialog: false,
      studentsNames: [],
    }
  },
  methods: {
    searchStudents() {
      this.studentsNames = [];
      this.loading = true;
      if (this.activityType === 'activity') {
        axios.get('teacher/reply/all/' + this.activityId).then(response => {
          const { students } = response.data;
          this.studentsNames = students;
          this.studentsNames.map(data => data.name = data.name + ' ' + data.lastName + ' ' + data.secondLastName)
        })
        this.loading = false;

      } else {
        axios.get('teacher/poll_reply/all/' + this.activityId).then(response => {
          const { students } = response.data;
          this.studentsNames = students;
          this.studentsNames.map(data => data.name = data.name + ' ' + data.lastName + ' ' + data.secondLastName)
        })
        this.loading = false;

      }
    },
    studentDetails(student) {
      if (this.activityType === 'activity') {
        this.$router.push('/profe/respuestas/' + student.id + '/' + this.activityId)
      } else {
        this.$router.push('/profe/respuestasP/' + student.id + '/' + this.activityId)
      }
    }
  },
  created() {


  }

}
</script>