<template>
  <teacher-drawer>
    <v-col xl="12" lg="12" md="12" sm="12" xs="12" align="center">
      <template slot:default>
        <router-view />
      </template>
    </v-col>
  </teacher-drawer>
</template>

<script>
import TeacherDrawer from "../../components/teacher/TeacherDrawer";
export default {
  components: {TeacherDrawer}
}
</script>