<template>
  <v-container fluid>
    <v-row class="mt-5" v-if="!loading">
      <v-col v-if="questions.length === 0">
        <v-img width="60%" :src="require('@/assets/images/nodata.jpg')"></v-img>
        <p>Actualmente no posee preguntas creadas</p>
        <question-form :text-id="parseInt(textId)" />
      </v-col>
    </v-row>
    <v-card v-if="!loading && questions.length > 0">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar pregunta por título" single-line hide-details>
        </v-text-field>
      </v-card-title>
      <v-data-table :search="search" :loading="loadingTable" :headers="headers" :items="questions" item-key="id"
        class="elevation-1">
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="questionDetails(item)">
            mdi-magnify
          </v-icon>
        </template>
        <template v-slot:item.difficulty="{ item }">
          <v-chip dark :color="chipColor(item).color">
            {{ chipColor(item).difficulty }}
          </v-chip>
        </template>
        <template v-slot:item.order="{ item }">
          <v-text-field :disabled="loadingTable" @keyup.enter="changeOrder(item)" v-model="item.order" append-icon="mdi-content-save"
            type="number" :value="item.order" @click:append="changeOrder(item)" />
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-col>
        <v-progress-circular v-if="loading" size="200" :width="3" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import QuestionForm from "../../../../components/teacher/forms/QuestionForm";
export default {
  components: { QuestionForm },
  computed: {
    questions() {
      return this.$store.getters['teacher/getQuestions'];
    }
  },
  data() {
    return {
      search: '',
      loadingTable: false,
      headers: [],
      textId: null,
      loading: null
    }
  },
  methods: {
    async changeOrder({ id, order }) {
      try {
        this.loadingTable = true;
        const data = {
          id,
          order
        }
        await axios.post('teacher/question/order', data);
        await this.$store.dispatch('teacher/questions', this.textId);
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingTable = false;
      }
    },
    questionDetails({ id }) {
      this.$router.push('/profe/pregunta/' + id);
    },
    chipColor({ difficulty }) {
      if (difficulty === 0) {
        return { color: 'green', difficulty: 'Fácil' }
      }
      if (difficulty === 1) {
        return { color: 'orange', difficulty: 'Intermedio' }
      }
      if (difficulty === 2) {
        return { color: 'red', difficulty: 'Difícil' }
      }
    },
  },
  async created() {
    this.loading = true;
    this.textId = this.$route.params.id;
    await this.$store.dispatch('teacher/questions', this.textId);
    this.loading = false;
    this.headers.unshift(
      {
        text: 'Título',
        align: 'start',
        value: 'title',
        sortable: false,
      },
      {
        text: 'Dificultad',
        align: 'start',
        value: 'difficulty',
        sortable: false,
      },
      {
        text: 'Orden',
        value: 'order',
        sortable: false
      },
      {
        text: 'Detalles',
        value: 'actions',
        sortable: false
      },

    )

  }
}
</script>
