<template>
  <v-container fluid>
    <div v-if="!loading && pollsData.length === 0">
      <v-img
          width="60%"
          :src="require('@/assets/images/nodata.jpg')"
      ></v-img>
      <p>Actualmente no posee encueastas creadas</p>
    </div>

    <v-row v-if="!loading && pollsData.length > 0">
      <v-autocomplete
          @change="toDetailsBar"
          :items="pollsData"
          item-text="title"
          outlined
          dense
          label="Buscar encuesta"
          return-object
      ></v-autocomplete>
    </v-row>

    <v-row v-if="!loading && pollsData.length > 0" justify="center">
      <v-col v-for="(poll, index) in showableArray" :key="index" xl="3" lg="4" md="4" sm="12">
        <card-component
            :id="poll.id"
            :title="poll.title"
            :description="poll.description"
            image="lesson"
        >
          <template>
            <v-btn
                color="success"
                @click="toDetails(poll.id)"
            >
              Ver Detalles
            </v-btn>
          </template>
        </card-component>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <v-progress-circular
            v-if="loading"
            size="200"
            :width="3"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-container fluid v-if="!loading && pollsData.length > 0">
      <v-btn
          v-for="(index, i) in paginate"
          :key="i"
          @click="current_page = index - 1"
          elevation="3"
          color="primary"
          fab
          style="margin-right: 10px"
      >
        {{ index }}
      </v-btn>
    </v-container>

  </v-container>
</template>

<script>

import CardComponent from "../../../components/UI/CardComponent";

export default {

  data () {
    return {
      loading: null,
      items_per_page: 6,
      current_page: 0,
    }
  },

  components: { CardComponent },
  computed: {
    pollsData() {
      return this.$store.getters['teacher/getPolls'];
    },
    paginate() {
      return  Math.ceil((this.pollsData.length / this.items_per_page));
      // largo del arreglo / items por pagin - redondea para arriba
    },
    showableArray() {
      return this.pollsData.slice(this.current_page*this.items_per_page, this.items_per_page + this.current_page*this.items_per_page);
    }
  },

  methods: {
    async getPolls() {
      this.loading = true;
      await this.$store.dispatch('teacher/poll');
      this.loading = false;
    },
    toDetails(id) {
      this.$router.push('/profe/encuestas/' + id);
    },
    toDetailsBar(lesson) {
      this.$router.push('/profe/encuestas/' + lesson.id);
    }
  },
  created() {
    this.getPolls();
  }
}
</script>
