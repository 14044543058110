<template>
  <v-container fluid>
    <div v-if="!loading && lessonsData.length === 0">
      <v-img width="60%" :src="require('@/assets/images/nodata.jpg')"></v-img>
      <p>Actualmente no posee asignaturas creadas</p>
      <lesson-form class="mt-5" />
    </div>

    <v-card v-if="!loading && lessonsData.length > 0">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar asignatura" single-line hide-details>
        </v-text-field>
      </v-card-title>
      <v-data-table :search="search" :headers="headers" :items="lessonsData" item-key="id" class="elevation-1">
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" class="mr-2" @click="toDetails(item)">
            mdi-magnify
          </v-icon>
        </template>
      </v-data-table>
    </v-card>


    <v-row justify="center">
      <v-col>
        <v-progress-circular v-if="loading" size="200" :width="3" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import LessonForm from "../../../components/teacher/forms/LessonForm";

export default {

  data() {
    return {
      search: '',
      headers: [],
      loading: null,
      items_per_page: 6,
      current_page: 0,
    }
  },

  components: { LessonForm },
  computed: {
    lessonsData() {
      return this.$store.getters['teacher/getLessons'];
    },
  },

  methods: {
    async getLessons() {
      this.loading = true;
      await this.$store.dispatch('teacher/lessons');
      this.loading = false;

    },
    toDetails({id}) {
      this.$router.push('/profe/asignaturas/' + id);
    },
  },
  created() {
    this.getLessons();
    this.headers.unshift(
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Descripción',
        align: 'start',
        value: 'description',
        sortable: false,
      },
      {
        text: 'Año',
        value: 'year',
        sortable: false
      },
      {
        text: 'Periodo',
        value: 'period',
        sortable: false
      },
      {
        text: 'Ver',
        value: 'actions',
        sortable: false
      },


    )
  }
}
</script>
