<template>
  <v-app style="background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);">
    <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
      <div>
        <v-tabs v-model="tab" show-arrows background-color="purple darken-4" icons-and-text dark grow>
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab v-for="i in tabs" :key="i.id">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1">{{ i.name }}</div>
          </v-tab>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>

                <v-form lazy-validation @submit.prevent="submit">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="password" :rules="[rules.required, rules.min, passwordMatch]"
                        type="password" name="input-10-1" label="Contraseña" hint="Mínimo 8 caracteres" counter>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="password2" :rules="[rules.required, rules.min]" type="password"
                        name="input-10-1" label="Repita Contraseña" hint="Mínimo 8 caracteres" counter></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                      <v-btn :loading="loading" x-large block :disabled="!valid" color="deep-purple lighten-1"
                        type="submit"> Cambiar Contraseña </v-btn>
                    </v-col>
                  </v-row>
                </v-form>


              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  computed: {
    passwordMatch() {
      if (this.password === this.password2) return true
      return "Contraseñas deben coincidir."
    },
    valid() {
      if (this.password === this.password2 && this.password.length >= 8) {
        return true
      }
      return false;
    }
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await axios.post('/resetPassword', { password: this.password }, { headers: { token: this.$route.params.token } })
        this.loading = false;
        Swal.fire({
          icon: 'success',
          title: ' ',
          text: 'Contraseña generada con éxito!',
        }).then(() => this.$router.push('/login'))
      } catch (e) {
        console.log(e)
      }

    },
  },
  data: () => ({
    loading: null,
    dialog: true,
    tab: 0,
    tabs: [
      { id: 1, name: "Cambiar contraseña.", icon: "mdi-account" },
    ],

    password: "",
    password2: "",

    show1: false,
    rules: {
      required: value => !!value || "Campo Requerido",
      min: v => (v && v.length >= 8) || "Min 8 caracteres"
    }
  }),
}
</script>

