<template>
  <div>

    <v-tabs v-model="tab">
      <v-tab href="#tab-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Asignatura
            </v-btn>
          </template>
          <span>Detalles asignatura.</span>
        </v-tooltip>
      </v-tab>
      <v-tab href="#tab-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Añadir
            </v-btn>
          </template>
          <span>Muestra todos los alumnos inscritos en la plataforma, mas no en la asignatura.</span>
        </v-tooltip>
      </v-tab>
      <v-tab href="#tab-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Listado
            </v-btn>
          </template>
          <span>Muestra todos los alumnos inscritos en la asignatura.</span>
        </v-tooltip>
      </v-tab>
      <v-tab href="#tab-4">Crear Actividad</v-tab>
      <v-tab href="#tab-5">Encuesta</v-tab>
      <v-tab href="#tab-6">Actividades Creadas</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <lesson-info />
      </v-tab-item>

      <v-tab-item value="tab-2">
        <add-students />
      </v-tab-item>

      <v-tab-item value="tab-3">
        <current-students />
      </v-tab-item>

      <v-tab-item value="tab-4">
        <assignment-form :lessonId="this.$route.params.id" />
      </v-tab-item>

      <v-tab-item value="tab-5">
        <poll-activity-form :lessonId="this.$route.params.id" />
      </v-tab-item>

      <v-tab-item value="tab-6">
        <lesson-activities />
      </v-tab-item>

    </v-tabs-items>


  </div>
</template>

<script>
import CurrentStudents from "./details/CurrentStudents";
import AssignmentForm from "../../../components/teacher/forms/AssignmentForm";
import axios from "axios";
import AddStudents from "./details/AddStudents";
import LessonInfo from "./details/LessonInfo";
import LessonActivities from "./details/LessonActivities";
import PollActivityForm from '../../../components/teacher/forms/PollActivityForm.vue';

export default {
  components: { LessonInfo, AddStudents, CurrentStudents, AssignmentForm, LessonActivities, PollActivityForm },
  data() {
    return {
      lessonId: null,
      activities: [],
      tab: null,
    }
  },
  methods: {
    async getActivities() {

      await axios.get('teacher/activityTeacher/' + this.lessonId)
        .then(response => {
          const { activity } = response.data;
          this.activities = activity;
        })
    },
    async activityDetails(id) {
      await this.$router.push('/profe/grafico/' + id)
    },
  },
  async created() {
    this.lessonId = this.$route.params.id;
    await this.getActivities();
  }
}
</script>
