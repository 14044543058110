<template>
  <v-card class="mt-4" width="100vw" v-if="!loading">
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-text-field label="Autor" v-model="textData.author" :value="textData.author"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field label="Título" v-model="textData.title" :value="textData.title"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field label="Descripción" v-model="textData.description" :value="textData.description"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field label="Fecha Publicación" v-model="textData.publicationYear" :value="textData.publicationYear">
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete label="Tipo" v-model="textData.type" :items="type" :value="textData.type">
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-text-field label="Fuente" v-model="textData.font" :value="textData.font"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn class="ma-2 white--text" @click="editText" color="deep-purple accent-4">
            Editar texto
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-for="(data, i) in textParagraphs" :key="i" align="center" justify="start">
        <v-col cols="10">
          <v-text-field label="Subtítulo (Opcional)" :value="data.subTitle" v-model="data.subTitle"></v-text-field>
        </v-col>
        <v-col cols="10">
          <v-textarea filled :label="'Párrafo: ' + (i + 1)" :value="data.description" v-model="data.description" />
        </v-col>
        <v-col cols="2">
          <v-btn @click="editParagraph(data)" class="ma-2 white--text" color="deep-purple accent-4">
            Editar párrafo
          </v-btn>
          <key-words :text="data.description" :paragraphId="data.id" :payload="data.payload"/>
        </v-col>
        <v-col cols="3">
          <v-file-input @change="addImage(data)" :loading="loadingFile" accept="image/jpg"
            placeholder="Seleccione una Imágen" prepend-icon="mdi-camera" v-model="image"
            label="Añadir imágen formato JPG" />
        </v-col>

        <v-img v-if="data.image" height="40%" width="40%" :src="data.image"></v-img>
      </v-row>
    </v-card-text>
    <div class="text-center ma-2">
      <v-snackbar :color="color" v-model="snackbar">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-card>
  <v-row v-else justify="center">
    <v-col>
      <v-progress-circular size="200" :width="3" color="primary" indeterminate></v-progress-circular>
    </v-col>
  </v-row>

</template>

<script>
import axios from "axios";
import KeyWords from '../../../../components/teacher/forms/KeyWords.vue';
export default {
  components: { KeyWords },
  data() {
    return {
      type: ["Texto descriptivo", "Texto expositivo", "Texto científico", "Texto argumentativo", "Texto literario",
        "Texto publicitario", "Texto instructivo", "Texto histórico", "Texto jurídico", "Texto digital", "Texto periodístico", "Diagnóstico", "Minijuego"],
      text: '',
      color: '',
      snackbar: false,
      loadingFile: null,
      image: null,
      textData: [],
      textParagraphs: [],
      textSize: '17px',
      textId: null,
      loading: null
    }
  },
  methods: {
    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack;
    },
    async editText() {
      try {
        await axios.put('teacher/text/editText/' + this.textId, this.textData);
        this.setSnackBar('green', 'Texto modificado con éxito.', true);
      } catch (e) {
        console.log(e);
        this.setSnackBar('red', 'Error.', true);
      }
    },
    async editParagraph({ id, description, subTitle }) {
      try {
        const data = { description, subTitle };
        await axios.put('teacher/text/edit/' + id, data);
        this.setSnackBar('green', 'Párrafo modificado con éxito.', true);
      } catch (e) {
        console.log(e);
        this.setSnackBar('red', 'Error.', true);
      }
    },
    async addImage({ id }) {
      if (!this.image) return;
      try {
        this.loadingFile = true;
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const data = {
          image: this.image
        };
        await axios.post('teacher/text/image/' + id, data, config);
        await this.getText();

      } catch (e) {
        console.log(e);
      } finally {
        this.image = null;
        this.loadingFile = false;
      }
    },
    async getText() {
      const response = await axios.get('teacher/text/' + this.textId)
      this.textData = response.data.text;
      this.textParagraphs = response.data.text.paragraph;
    },
  },
  async created() {
    this.loading = true;
    this.textId = this.$route.params.id;
    await this.getText();
    this.loading = false;
  },
}
</script>
