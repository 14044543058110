export default {
    getStudentsInLesson(state) {
        return state.studentsInLesson;
    },
    getQuestions(state) {
        return state.questions;
    },
    getQuestion(state) {
        return state.question;
    },
    getAnswers(state) {
        return state.answers;
    },
    getLessons(state) {
        return state.lessons;
    },
    getTexts(state) {
        return state.texts;
    },
    getLesson(state) {
        return state.lesson;
    },
    getPolls(state) {
        return state.polls;
    }
}
