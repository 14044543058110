<template>
  <v-row justify="center">
    <v-dialog persistent v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="bottomStyle" color="primary" v-bind="attrs" v-on="on">
          Crear Encuesta
        </v-btn>
        <v-btn v-else text block v-bind="attrs" v-on="on">
          Crear Encuesta
        </v-btn>
      </template>

      <v-container fluid>
        <v-row justify="center">
          <v-col justify="center">
            <v-card>
              <v-form @submit.prevent="submit" ref="form" lazy-validation>
                <v-card-text>
                  <v-text-field v-model="form.title" prepend-icon="mdi-format-title" label="Título encuesta">
                  </v-text-field>
                  <v-textarea prepend-icon="mdi-content-copy" v-model="form.description" auto-grow filled
                    color="deep-purple" label="Descripción (Opcional)" rows="5"></v-textarea>
                  <v-file-input v-model="form.pdf" placeholder="Seleccione una Imágen" prepend-icon="mdi-camera"
                    label="Añadir imágen formato JPG" />
                </v-card-text>

                <v-card-actions>
                  <v-btn color="red" text @click="cancel"> Salir </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text type="submit">
                    Crear Encuesta
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-center ma-2">
        <v-snackbar :color="color" v-model="snackbar">
          {{ text }}.

          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  props: {
    textId: {
      type: Number,
    },
    bottomStyle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      snackbar: false,
      color: "",
      text: "",
      dialog: false,
      form: {
        title: "",
        description: "",
        pdf: null
      },
    };
  },
  methods: {
    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack;
    },
    async resetForm() {
      this.form = {
        title: "",
        description: "",
        pdf: null,
      };
    },
    async submit() {
      if (
        this.form.title === "" ||
        this.form.pdf === null
      ) {
        this.setSnackBar("red", "Faltan Datos", true);
        return;
      }

      try {
        const blob = new Blob([this.form.pdf], {
          type: 'data:application/pdf'
        })
        const formData = new FormData();
        formData.append('pdf', blob);
        formData.append('title', this.form.title);
        formData.append('description', this.form.description);
        await axios.post('teacher/poll', formData);
      } catch (e) {
        console.log(e)
      }

      this.setSnackBar("green", "Encuesta creada con éxito", true);
      await this.$store.dispatch('teacher/poll');
      this.resetForm();
    },

    async cancel() {
      await this.resetForm();
      this.dialog = false;
    },
  },
};
</script>
