import axios from "axios";

export default {
  async logOut(context) {
    await localStorage.removeItem("token");
    await sessionStorage.removeItem("token");
    await context.commit("setToken", null);
    await context.commit("setUserInfo", null);
  },

  async login(context, data) {
    try {
      const response = await axios.post("login", data.login);

      let token = response.data.token;

      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      await context.commit("setToken", token);
      if (data.check) {
        await localStorage.setItem("token", response.data.token);
      } else {
        await sessionStorage.setItem("token", response.data.token);
      }
      await context.commit("setUserInfo", response.data.user);
    } catch (e) {
      console.log("io", e.toJson());
    }
  },
  async signIn(context, data) {
    try {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      const response = await axios.post("createUser", data);
      const token = response.data.token;
      axios.defaults.headers.common["token"] = token;

      await context.commit("setToken", token);

      localStorage.setItem("token", response.data.token);

      await context.commit("setUserInfo", response.data.user);
    } catch (e) {
      console.log(e);
    }
  },
  async userInfo(context) {
    let response = await axios.get("verifyUser");
    if (!response.data.ok) {
      await localStorage.removeItem("token");
      await sessionStorage.removeItem("token");
      await context.commit("setToken", null);
      await context.commit("setUserInfo", null);
      return;
    }
    await context.commit("setUserInfo", response.data.user);
  },
};
