<template>
  <v-card>
    <v-card-text>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar Estudiantes" single-line hide-details>
      </v-text-field>
    </v-card-text>
    <v-data-table :search="search" v-model="selected" :headers="headers" :items="students" :single-select="singleSelect"
      item-key="id" show-select class="elevation-1">
    </v-data-table>
    <div class="text-center pt-2">
      <v-btn color="primary" class="mr-2" @click="addStudent" :disabled="selected.length === 0">Agregar Estudiantes
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      search: '',
      students: [],
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: 'Nombre Estudiante',
          align: 'start',
          value: 'name',
        },
        { text: 'Apellido Paterno', value: 'lastName' },
        { text: 'Apellido Materno', value: 'secondLastName' },
        { text: 'email', value: 'email' },
        { button: 'agregar' }
      ],
    }
  },
  methods: {
    async getStudents() {
      const id = this.$route.params.id;

      const response = await axios.get('teacher/students/' + id);
      const { student } = response.data;
      this.students = student;

    },

    async addStudent() {
      let id = this.$route.params.id;
      let studentsIds = [];
      this.selected.map(data => studentsIds.push(data.id));

      let data = {
        lessonId: this.$route.params.id,
        studentId: studentsIds,
      }

      await axios.post('teacher/student_lesson', data);
      this.selected = [];
      await this.getStudents();
      await this.$store.dispatch('teacher/studentsInLesson', id);

    }

  },
  created() {
    this.getStudents();
  }
}
</script>
