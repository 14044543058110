export default {
    studentsInLesson: [],
    questions: [],
    question: [],
    answers: [],
    lessons: [],
    lesson: null,
    texts: [],
    polls: []
}
